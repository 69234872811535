html,
body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
}

body {
  font: 18px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
  width: 100%;
}

main {
  text-align: center;
  height: 100%;
  width: 100%;
}

h1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

button {
  margin-top: 1px;
  margin-bottom: 1px;

  margin-left: auto;
  margin-right: auto;

  font: 18px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
}

.container {
  margin-left: 20px;
  margin-right: 20px;

  max-width: 100%;
  max-height: 800px;
}

canvas {
  width: 100%;
  height: 100%;
}

dialog form > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

dialog {
  background-color: #f7f7f7;
  color: #363636;
  border-radius: 6px;
  border: #dbdbdb;
  border-color: #dbdbdb;
  padding: 10px 30px;
}

dialog > header:first-child {
  background-color: #efefef;
  border-radius: 6px 6px 0 0;
  margin: -10px -30px 10px;
  padding: 10px;
  text-align: center;
}

input[type="submit"] {
  display: block;

  width: 50px;
  height: 35px;
  padding: 5px;
  margin: 0 auto;
}
